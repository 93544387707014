import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <p>{`I am a freelance front-end engineer from South Africa and make myself available for working with existing teams or as a consultant/developer for entrepreneurs.`}</p>
    <p>{`I understand that what entrepreneurs and employers are looking for are problem solvers. My goal is to provide value for my clients and not just be a 'pretty maker'. That's why I don't sell websites. Instead I provide solutions to business problems.`}</p>
    <p>{`When working with me, you can expect a partner who is fully dedicated to moving the needle by helping solve the problems your business is facing and helping you get the results you desire.`}</p>
    <p>{`I also always finish what I start, an trait often overlooked, so you can be at ease that your project will be completed. Working with me is a collaborative process filled with mutual respect, clear goals and winning solutions that aims to push your business to the next level.`}</p>
    <h3>{`I am proficient in the following technologies:`}</h3>
    <p><strong parentName="p">{`Languages`}</strong></p>
    <ul>
      <li parentName="ul">{`HTML`}</li>
      <li parentName="ul">{`CSS`}</li>
      <li parentName="ul">{`JavaScript`}</li>
    </ul>
    <p><strong parentName="p">{`Frameworks`}</strong></p>
    <ul>
      <li parentName="ul">{`React`}</li>
      <li parentName="ul">{`Gatsby`}</li>
      <li parentName="ul">{`Tailwind CSS`}</li>
      <li parentName="ul">{`Linaria CSS-in-JS`}</li>
    </ul>
    <p><strong parentName="p">{`Headless CMSs`}</strong></p>
    <ul>
      <li parentName="ul">{`Contentful`}</li>
      <li parentName="ul">{`Sanity`}</li>
      <li parentName="ul">{`Forestry`}</li>
      <li parentName="ul">{`Netlify CMSs`}</li>
      <li parentName="ul">{`Strapi`}</li>
      <li parentName="ul">{`Ghost`}</li>
    </ul>
    <p><strong parentName="p">{`E-Commerce`}</strong></p>
    <ul>
      <li parentName="ul">{`Stripe`}</li>
      <li parentName="ul">{`Snipcart`}</li>
      <li parentName="ul">{`Shopify`}</li>
      <li parentName="ul">{`BigCommerce`}</li>
    </ul>
    <p><strong parentName="p">{`Deployment`}</strong></p>
    <ul>
      <li parentName="ul">{`Netlify`}</li>
      <li parentName="ul">{`Vercel`}</li>
      <li parentName="ul">{`Github Pages`}</li>
      <li parentName="ul">{`Heroku`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      