import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Get in Touch`}</h2>
    <p>{`You can get in touch with me via `}<a href='https://wa.me/+27727121024' target='_blank' rel='noopener noreferrer'>{`whatsapp`}</a>{` or leave a message via `}<a href='mailto:info@chaddwebdesign.co.za' target='_blank' rel='noopener noreferrer'>{`email`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      